<template>
  <div>
    <Modal
      :value="value"
      title="种植户详细信息"
      fullscreen
      @on-visible-change="modalChange"
      @input="(val) => $emit('input', val)"
    >
      <!-- 企业信息 -->
      <!-- 编码、种植户名称、地址、位置、土地总规模、地块信息{种植品种、位置、面积、种植方式{单种、间作、套种}、地力等级}、种植档案、施用肥档案等。 -->
      <Form :label-width="100" style="display: flex">
        <div class="form-title" style="width: 200px">
          <h4>企业信息</h4>

          <FormItem>
           <img v-if="data.logo!=''" :src='imgSrc' alt="" width="100" height="100" @click="lookPicture(imgSrc)">
           <img v-else src="/imgs/empty/empty.png" alt="" width="100" height="100">
          </FormItem>

          <div style="width:100%;text-align:center">公司logo</div>
        </div>
        <Row style="margin-top: 20px">
          <Col span="8" style="padding-left:60px">
            <FormItem label="企业名称：" >
              <Input
                v-model="data.planterName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="编码：">
              <Input
                v-model="data.codeValue"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="企业类型：">
              <Input
                v-model="data.enterpriseTypeName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8" style="padding-left:60px">
            <FormItem label="地址：">
              <Input
                v-model="data.plantingAddress"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="营业期限：">
              <Input
                v-model="data.businessTerm"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="省市区编码：">
              <Input
                v-model="data.regionCodeName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="种植品种：" style="padding-left:60px">
              <Input
                v-model="data.typeName"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="联系人：">
              <Input v-model="data.name" readonly style="width: 260px"></Input>
            </FormItem>
          </Col>
          <Col span="7">
            <FormItem label="联系电话：">
              <Input v-model="data.tel" readonly style="width: 260px"></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="统一社会信用代码：" :label-width="160">
              <Input
                v-model="data.creditCode"
                readonly
                style="width: 260px"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="营业范围：">
              <Input
                v-model="data.businessScope"
                readonly
                style="width: 260px"
                type="textarea"
                :row="5"
              ></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>

      <!-- 基地信息 -->
      <div v-for="(item,index) in info" :key="index">
        <Form  :label-width="100" style="display: flex">
          <div class="form-title" style="width: 145px">
            <h4>基地信息</h4>

            <FormItem>
              <img v-if="item.imgUrl!=''" :src='servicePath + item.imgUrl' @click="lookPicture(servicePath + item.imgUrl)" alt="" width="100" height="100">
              <img v-else src="/imgs/empty/empty.png/" alt="" width="100" height="100">
            </FormItem>

            <div style="width:100%;text-align:center">基地图片</div>
          </div>

          <Row style="margin-top: 20px">
            <Col span="8" style="padding-left:37px">
              <FormItem label="种植基地名称：" :label-width="120"> 
                <Input
                  v-model="item.name"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="联系人：">
                <Input
                  v-model="item.chargePerson"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="联系电话：">
                <Input
                  v-model="item.chargePersonTel"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8" style="padding-left:37px">
              <FormItem label="种植基地面积：" :label-width="120">
                <Input v-model="item.area" readonly style="width: 260px">
                  <span slot="append">㎡</span>
                </Input>
              </FormItem>
            </Col>

            <Col span="8">
              <FormItem label="所属区域：">
                <Input
                  v-model="item.region"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="详细地址：">
                <Input
                  v-model="item.address"
                  readonly
                  style="width: 260px"
                ></Input>
              </FormItem>
            </Col>
            <Col span="8" style="padding-left:57px">
              <FormItem label="基地绘制：">
                <Input v-model="item.mapPosition" disabled style="width: 260px">
                  <span
                    slot="append"
                    class="iconfont"
                    style="cursor: pointer"
                    @click="drawMap"
                    >&#xe648;</span
                  >
                </Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col span="2"></Col>
          <Col style="22">
            <Table :columns="columns1" :data="data1" :width="1200"></Table>
          </Col>
        </Row>
      </div>

      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.$emit('input', false)">确定</Button>
      </p>
    </Modal>
    
    <!-- 种植档案 -->
    <Modal
      v-model="flag"
      title="种植档案"
      fullscreen
      @on-visible-change="modalChange1"
    >
    <x-table
      :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="columns2"
      :data="data2"
      @page-change="pageChange"
    ></x-table>
    <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.flag=false">确定</Button>
      </p>
    </Modal>

    <!-- 施肥档案 -->
    <Modal
      v-model="flag1"
      title="施肥档案"
      fullscreen
    >
    <x-table
      :no-data-text="CA('plant_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="columns3"
      :data="data3"
      @page-change="pageChangeTwo"
    ></x-table>
    <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => this.flag1=false">确定</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
  
</template>

<script>
import pictureView from "@/components/picture_view";
export default {
  components:{
    pictureView,
  },
  data() {
    return {
      data: [],
      info: [],
      imgSrc:'',
      plantingData: [],
      drawMapModalShow: false,
      baseImgSrc:'',
      modal: {
        show: false,
      },
      picture: {
        show: false,
        src: "",
      },
      columns1: [
        {
          title: "地块编号",
          key: "name",
        },
        {
          title: "地块面积",
          key: "avgWeight",
        },
        {
          title: "地力等级",
          key: "areaLevel",
        },
        {
          title: "备注",
          key: "name",
        },
        {
          title: "操作",
          width: 200,
          render: (h, { row }) => {
            return (
              <div>
                {this.CA("plant_edit") && (
                  <a
                    style="margin-right:20px"
                    on-click={() => this.plantingFile(row)}
                  >
                    种植档案
                  </a>
                )}
                {this.CA("plant_edit") && (
                  <a
                    style="margin-right:20px"
                    on-click={() => this.fertilizerFile(row)}
                  >
                    施肥档案
                  </a>
                )}
              </div>
            );
          },
        },
      ],
      //种植档案
      columns2: [
        {
          title: "所属地块",
          key: "sceneName",
        },
        {
          title: "种植品种",
          key: "categoryName",
        },
        {
          title: "种植方式",
          key: "plantName",
        },
        {
          title: "预计结束时间",
          key: "name",
        },
        {
          title: "粪肥供给养分占比",
          key: "name",
          render: (h, { row }) => {
              return <span>{row.fertilizationSupplyPercentage * 100} %</span>;
            },
        },
        {
          title: "粪肥占施肥比列",
          key: "name",
          render: (h, { row }) => {
              return <span>{row.manureFertilizationPercentage * 100} %</span>;
            },
        },
        {
          title: "作物元素",
          key: "elementName",
        },
        {
          title: "状态",
          key: "statusName",
        },
      ],
      //施肥档案
      columns3: [
        {
          title: "施用基地",
          key: "farmName",
        },
        {
          title: "施用地块",
          key: "sceneName",
        },
        {
          title: "施用量",
          key: "amount",
          render: (h, { row }) => {
            return <span>{row.amount}kg</span>;
          },
        },
        {
          title: "施用时间",
          key: "applyTime",
          render: (h, { row }) => {
            let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
            return <span>{str}</span>;
          },
        },
      ],
      data1: [],
      data2: [],
      data3: [],
      flag:false,
      flag1:false,
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      page1: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      servicePath:''
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    listDetailInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listDetailData: {
      type: Array,
      default: () => {
        return {};
      },
    },
    plantingInfoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    modalChange(validate) {
      this.data = this.listDetailInfo;
      this.info = this.listDetailData;
      this.listDetailData.forEach((item)=>{
          this.data1=item.sceneList
      })
      this.plantingData = this.plantingInfoData;
      this.imgSrc=this.getImgUrl(this.data.servicePath, this.data.logo)
      this.servicePath = this.data.servicePath
    },
    //种植档案
    plantingFile(row) {
        this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        farmId: row.farmId,
        sceneId: row.sceneId,
        categoryId: row.categoryId,
        categoryName:row.categoryName,
        num:row.num
      }).then(res=>{
          this.data2=res.list
          this.flag=true
      })
    },
    //施肥档案
    fertilizerFile(row) {
        this.$post(this.$api.WASTE_APPLICATION.PLANT_LIST, {
      }).then(res=>{
          this.data3=res.list
          this.flag1=true
      })
    },
    drawMap() {
      this.drawMapModalShow = true;
    },
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
    modalChange1(visible){
        
    },
    pageChange(pageNo) {
    //   this.page.pageNo = pageNo;
    },
    pageChangeTwo(pageNo) {
    //   this.page.pageNo = pageNo;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ div {
  .ivu-form-item {
    .ivu-form-item-content {
      margin-left: 0 !important;
    }
  }
  .form-title {
      padding: 10px 20px;
  }
  h4 {
    margin-bottom: 10px;
  }
}
</style>